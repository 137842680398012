import { useMemo } from 'react';
import { useCountriesForFeature } from '@noah-labs/fe-shared-data-access-countries';
import { useFiatPaymentMethodTokenizedCardSaveMutation } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserDefaultBillingAddress, useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { UnsupportedPayoutCardAlert, usePushAlert } from '@noah-labs/fe-shared-ui-components';
import {
  getFiatPaymentCardScheme,
  TpPaymentMethod,
  useRouter,
} from '@noah-labs/fe-shared-ui-shared';
import { useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature, FinService } from '@noah-labs/shared-schema-gql';
import type { FrameCardTokenizedEvent } from 'frames-react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { EnterPaymentInfoScene } from '../../scenes/EnterPaymentInfo';
import type { StSellRouter } from './SellRouter';

type PpEnterPaymentInfo = TpStateMachine<StSellRouter>;

export function EnterPaymentInfo({ updateState }: PpEnterPaymentInfo): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const defaultAddress = useUserDefaultBillingAddress();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.HomeAddress?.CountryCode,
    [Feature.Sell],
    webConfigBrowser.regions.ProhibitedCountries,
    webConfigBrowser.regions.RestrictedRegions,
  );
  const { push } = useRouter();
  const { state: locationState } = useLocation<{ successTo: string } | undefined>();
  const pushAlert = usePushAlert();

  const {
    error: saveCardError,
    isLoading: saveCardLoading,
    mutateAsync: saveCardMutation,
  } = useFiatPaymentMethodTokenizedCardSaveMutation();

  const onCardTokenized = useMemo(() => {
    if (typeof locationState?.successTo !== 'string') {
      return undefined;
    }

    return async (cardTokenized: FrameCardTokenizedEvent, save: boolean) => {
      try {
        const fpmProviderSaveData = await saveCardMutation({
          Input: {
            FinService: FinService.Cko,
            SaveForFutureUse: save,
            Token: cardTokenized.token,
          },
        });

        if (!fpmProviderSaveData.fiatPaymentMethodTokenizedCardSave.DynamoID) {
          throw new Error('Failed to save payment method');
        }

        const isPayoutSupported =
          fpmProviderSaveData.fiatPaymentMethodTokenizedCardSave.Details.Payout.EstimateHours;

        if (!isPayoutSupported) {
          pushAlert(UnsupportedPayoutCardAlert);
          return;
        }

        const id = fpmProviderSaveData.fiatPaymentMethodTokenizedCardSave.DynamoID;

        updateState({
          fiatCurrency: userData?.userProfile.fiatPaymentCurrency,
          payoutMethod: TpPaymentMethod.Card,
          selectedPayoutCard: {
            billingAddress: undefined,
            id,
            issuer: cardTokenized.issuer,
            last4: cardTokenized.last4,
            scheme: getFiatPaymentCardScheme(cardTokenized.scheme || ''),
          },
        });

        push(locationState.successTo);
      } catch {
        // Handled by useWalletError
      }
    };
  }, [
    locationState?.successTo,
    push,
    pushAlert,
    saveCardMutation,
    updateState,
    userData?.userProfile.fiatPaymentCurrency,
  ]);

  const isVisible = Boolean(useRouteMatch(walletRoutes().sell.enterPaymentInfo.path)?.isExact);

  const { ApiErrorScene } = useWalletError(saveCardError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <EnterPaymentInfoScene
      saveToggle
      countries={countriesData?.countries}
      ctaLabel="Add Card"
      defaultAddress={defaultAddress}
      fullName={userData?.userProfile.fullDisplayName}
      isLoading={saveCardLoading}
      isVisible={isVisible}
      pageTitle="Card Details"
      onCardTokenized={onCardTokenized}
    />
  );
}
