import { SecurityPin, TransactionPinThreshold } from '@noah-labs/fe-shared-feature-signing';
import { Consent } from '@noah-labs/fe-shared-feature-user';
import { AppContainer, AppHeaderTitle, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../components/layout/AppHeaderData';
import { AccessControlData } from '../auth/AccessControlData';
import { AccountLimits } from './controllers/AccountLimits';
import { CurrencyUnitSetting } from './controllers/CurrencyUnitSetting';
import { DisplayCurrencySetting } from './controllers/DisplayCurrencySetting';
import { LinkedAccounts } from './controllers/LinkedAccounts';
import { PaymentCurrencySetting } from './controllers/PaymentCurrencySetting';
import { PrimaryCurrencySetting } from './controllers/PrimaryCurrencySetting';
import { ProfileDetails } from './controllers/ProfileDetails';
import { Settings } from './controllers/Settings';
import { CreatePinRouter } from './CreatePinRouter';
import { PaymentMethodsRouter } from './PaymentMethodsRouter';
import { PinRevocationRouter } from './PinRevocationRouter';
import { RecoverPinWithSecretPhraseRouter } from './RecoverPinWithSecretPhraseRouter';
import { UpdatePinRouter } from './UpdatePinRouter';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      {/* User Settings */}
      <Route exact path={userRoutes.settings.base.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <Settings />
        </AccessControlData>
      </Route>
      <Route
        exact
        path={[
          userRoutes.settings.newPaymentMethod.path,
          userRoutes.settings.editPaymentMethod.path,
          userRoutes.settings.paymentMethods.path,
        ]}
      >
        <PaymentMethodsRouter />
      </Route>
      <Route exact path={userRoutes.settings.displayCurrency.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <DisplayCurrencySetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.paymentCurrency.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <PaymentCurrencySetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.bitcoinUnit.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <CurrencyUnitSetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.primaryCurrency.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <PrimaryCurrencySetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.accountLimits.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <AccountLimits />
        </AccessControlData>
      </Route>

      {/* Settings Consent Route */}
      <Route exact path={userRoutes.settings.consent.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <AppContainer headTitle={userRoutes.settings.consent.title}>
            <AppHeaderData backButton helpButton />
            <Consent
              /**
               * The Segment analytics script needs to be reloaded if there have been any changes so need use a real page navigation
               * This is why we have the nextAction and nextActionDirty functions passed to the Consent controller
               */
              nextActionDirty={(): void => window.location.reload()}
            />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.accounts.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <LinkedAccounts />
        </AccessControlData>
      </Route>

      {/* User Profile */}
      <Route exact path={userRoutes.profile.path}>
        <AccessControlData needsAuthStatus={['authenticated']}>
          <ProfileDetails />
        </AccessControlData>
      </Route>

      {/* Security pin */}
      <Route exact path={userRoutes.settings.pin.security.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.settings.pin.security.title}>
            <AppHeaderData helpButton backTo={userRoutes.settings.base.path}>
              <AppHeaderTitle>{userRoutes.settings.pin.security.title}</AppHeaderTitle>
            </AppHeaderData>
            <SecurityPin />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route
        path={[
          userRoutes.settings.pin.create.pin.path,
          userRoutes.settings.pin.create.secretPhrase.path,
          userRoutes.settings.pin.create.success.path,
        ]}
      >
        <CreatePinRouter />
      </Route>

      <Route
        path={[
          userRoutes.settings.pin.reset.secretPhrase.path,
          userRoutes.settings.pin.reset.pin.path,
          userRoutes.settings.pin.reset.success.path,
        ]}
      >
        <RecoverPinWithSecretPhraseRouter />
      </Route>

      <Route
        path={[
          userRoutes.settings.pin.update.currentPin.path,
          userRoutes.settings.pin.update.pin.path,
          userRoutes.settings.pin.update.success.path,
        ]}
      >
        <UpdatePinRouter />
      </Route>

      <Route exact path={userRoutes.settings.pin.threshold.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.settings.pin.threshold.title}>
            <AppHeaderData helpButton backTo={userRoutes.settings.base.path}>
              <AppHeaderTitle>{userRoutes.settings.pin.threshold.title}</AppHeaderTitle>
            </AppHeaderData>
            <TransactionPinThreshold />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route
        path={[
          userRoutes.settings.pin.revocation.revoke.path,
          userRoutes.settings.pin.revocation.unrevoke.path,
        ]}
      >
        <PinRevocationRouter />
      </Route>
    </Switch404>
  );
}
