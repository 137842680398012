import { RevokePin, UnrevokePin } from '@noah-labs/fe-shared-feature-signing';
import { AppContainer, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../components/layout/AppHeaderData';
import { AccessControlData } from '../auth/AccessControlData';

export function PinRevocationRouter(): React.ReactElement {
  return (
    <Switch404>
      {/* 
       Authenticated route to revoke/unrevoke the PIN.
       */}
      <Route path={userRoutes.settings.pin.revocation.revoke.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle="Revoke PIN">
            <AppHeaderData exitButton helpButton />
            <RevokePin successTo={walletRoutes().base.path} />
          </AppContainer>
        </AccessControlData>
      </Route>
      {/* 
       Authenicated or unauthenticated route to unrevoke the PIN.
       */}
      <Route path={userRoutes.settings.pin.revocation.unrevoke.path}>
        <AppContainer headTitle={userRoutes.settings.pin.revocation.unrevoke.title}>
          <AppHeaderData helpButton />
          <UnrevokePin successTo={walletRoutes().base.path} />
        </AppContainer>
      </Route>
    </Switch404>
  );
}
