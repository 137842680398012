import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { Balance, generatePath, getCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { useParams } from 'react-router-dom';
import { CompleteSellScene } from '../../scenes/CompleteSell';
import type { StSellRouter } from './SellRouter';

export function Complete({ state }: TpStateMachine<StSellRouter>): React.ReactElement {
  // TODO: we should pass the txid in the URL and get the real tx data from our API
  // sessionStorage is not reliable enough for this
  const { cryptoCurrency, params } = useWalletParams();
  const { fiatCurrency } = state;
  const { payoutStatus } = useParams<{ payoutStatus: TpCheckoutPayoutStatus }>();

  const { cryptoAmount, fiatAmount, payoutMethod } = state;

  const Amounts = getCurrencyAmountSlots({
    // set these to undefined when blank, so that they render a skeleton
    cryptoAmount: cryptoAmount === '' ? undefined : cryptoAmount,
    cryptoCurrency,
    cryptoUnit: CurrencyUnit.Default,
    fiatAmount: fiatAmount === '' ? undefined : fiatAmount,
    fiatCurrency,
    primaryCurrency: CurrencyDisplayType.Fiat,
    strikeThrough: payoutStatus === TpCheckoutPayoutStatus.failure,
  });

  return (
    <CompleteSellScene
      BalanceSlot={<Balance PrimaryAmountSlot={Amounts.PrimaryAmountSlot} />}
      payoutMethod={payoutMethod}
      payoutStatus={payoutStatus}
      transactionsUrl={generatePath(walletRoutes().account.base.path, params)}
    />
  );
}
