import { useCallback, useMemo, useState } from 'react';
import { ErrorPage, LoadingPage } from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { PinSuccessScene } from '@noah-labs/fe-shared-ui-signing/src/scenes/PinSuccess';
import { RevokePinScene } from '@noah-labs/fe-shared-ui-signing/src/scenes/RevokePin';
import { userRoutes } from '@noah-labs/fe-shared-util-routes/src/userRoutes';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { useSigning } from '../hooks/useSigning';
import { getRevokingError } from '../utils/getRevokingError';

export type PpRevokePin = {
  successTo: string;
};

export type TpParams = {
  documentType: string;
  revoke: boolean;
};

function getParams(search: string): TpParams {
  const params = new URLSearchParams(search);
  const documentType = params.get('documentType');
  const revoke = params.get('revoke');

  if (documentType !== 'NonCustodyKey') {
    throw new Error('Invalid document type');
  }

  return {
    documentType,
    revoke: revoke ? JSON.parse(revoke) : false,
  };
}

export function RevokePin({ successTo }: PpRevokePin): React.ReactElement {
  const { isJwtFetched, isRevokeLoading, revoke: revokeFn, sdStatus } = useSigning();
  const { search } = useRouter();

  const [actioned, setActioned] = useState(false);
  const [error, setError] = useState<unknown>();

  const params = useMemo(() => {
    try {
      return getParams(search);
    } catch (err) {
      setError(err);
      return undefined;
    }
  }, [search]);

  const isServiceReady = isJwtFetched && !isUndefinedOrNull(sdStatus);

  const onRevokePin = useCallback(async () => {
    if (!params) {
      return;
    }

    const { documentType, revoke } = params;

    if (documentType !== 'NonCustodyKey') {
      setError('Invalid document type');
      return;
    }

    try {
      await revokeFn({ documentType, revoke });
      setActioned(true);
    } catch (err) {
      setError(err);
    }
  }, [params, revokeFn]);

  if (error) {
    return <ErrorPage message={getRevokingError(error)} />;
  }

  if (!isServiceReady) {
    return <LoadingPage />;
  }

  if (actioned) {
    return (
      <PinSuccessScene
        sceneDescription="Your PIN will be revoked in 7 days. You will be able to create a new PIN when you login after the time has expired."
        sceneTitle={userRoutes.settings.pin.revocation.revoke.title}
        successTo={successTo}
      />
    );
  }

  return <RevokePinScene isLoading={isRevokeLoading} onRevokePin={onRevokePin} />;
}
