import type { TransactionFiatPaymentMethodDetails } from '@noah-labs/shared-schema-gql';

export function getFiatPaymentMethodUi(
  details: TransactionFiatPaymentMethodDetails | undefined,
): string | undefined {
  if (!details) {
    return undefined;
  }
  switch (true) {
    case 'AccountNumber' in details:
      return `Account number ${details.AccountNumber}`;
    case 'Last4' in details:
      return `${details.Scheme || 'Unknown card scheme'} ...${details.Last4}`;
    case 'Identifier' in details:
      return details.Identifier;
    default:
      return undefined;
  }
}
