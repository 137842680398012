import type { FrTransactionItemFragment } from '@noah-labs/fe-shared-data-access-wallet';
import { getRampFeeFiatAmount, getSourceRecordFee } from '@noah-labs/shared-currencies';
import type {
  TransactionDirection,
  TransactionFiatPaymentMethodDetails,
  TransactionStatus,
} from '@noah-labs/shared-schema-gql';
import { TransferDestinationType, TransferSourceType } from '@noah-labs/shared-schema-gql';
import { formatDate, formatTime, isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { getConfig } from '../config';
import { cryptoCurrencyFromCode } from '../cryptoCurrencies';
import { cryptoNetworkFromId } from '../cryptoNetworks';
import { fiatCurrencyFromCode } from '../fiatCurrencies';
import type {
  TpCryptoCurrencyUI,
  TpCryptoNetworkUI,
  TpFiatCurrencyUI,
  TpTransactionType,
} from '../types';
import { getFiatPaymentMethodUi } from './getFiatPaymentMethodUi';
import { getTransactionType } from './getTransactionType';
import {
  getMainPrimaryContent,
  getMainSecondaryContent,
  getStatus,
  getStatusText,
  positiveOrNegative,
} from './utils';

export type TpTransactionDataQa = {
  noahId?: string | null | undefined;
  orderId?: string | null | undefined;
  publicId?: string | null | undefined;
};
export type TpTransactionUi = {
  channelFiatAmount: string | undefined;
  created: string | undefined;
  cryptoAmount: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoNetwork: TpCryptoNetworkUI;
  dataQa: TpTransactionDataQa;
  description: string | undefined | null;
  direction: TransactionDirection | undefined | null;
  feeAmount: string | undefined;
  fiatAmount: string | undefined;
  fiatAmountWithFee: string | undefined;
  fiatCurrency: TpFiatCurrencyUI | undefined;
  fiatPaymentMethod: string | undefined;
  hasExpired: boolean;
  hasFee: boolean;
  id: string | undefined | null;
  isCustomer: boolean;
  /** on/off ramp - checkout (sell, buy) or manual off ramp (bank) */
  isRamp: boolean;
  mainPrimaryContent: React.ReactNode;
  mainSecondaryContent: React.ReactNode;
  marketPrice: string | null | undefined;
  noahID: string | undefined | null;
  publicID: string | undefined | null;
  status: TransactionStatus;
  statusText: string;
  type: TpTransactionType | undefined;
  withdrawOrderID: string | undefined | null;
};

type PpGetTransactionUi = {
  tx: FrTransactionItemFragment;
};
export function getTransactionUi({ tx }: PpGetTransactionUi): TpTransactionUi {
  const config = getConfig();
  const hasExpired = tx.Expiry ? new Date(tx.Expiry) <= new Date() : false;

  const isCheckoutRamp =
    tx.SourceType === TransferSourceType.Checkout ||
    tx.DestinationType === TransferDestinationType.Checkout;
  const isManualRamp = tx.DestinationType === TransferDestinationType.ManualRamp;
  const isRamp = isCheckoutRamp || isManualRamp;

  const isInternal = Boolean(tx.DestinationAccountID && tx.SourceAccountID);

  const fiatAmountObj = tx.RequestedAmount || tx.MarketAmount;

  const fiatCurrency = isUndefinedOrNull(fiatAmountObj)
    ? undefined
    : fiatCurrencyFromCode(fiatAmountObj.FiatCurrency);
  const fiatAmount = isUndefinedOrNull(fiatAmountObj)
    ? undefined
    : positiveOrNegative(fiatAmountObj.Amount, tx.Direction);
  const fiatAmountWithFee = isUndefinedOrNull(tx.MarketAmount)
    ? undefined
    : positiveOrNegative(tx.MarketAmount.Amount, tx.Direction);

  const cryptoCurrency = cryptoCurrencyFromCode(tx.Currency);
  const status = getStatus(tx, hasExpired);

  const created = `${formatDate(tx.Created, {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
  }).replace(',', '')}, ${formatTime(tx.Created)}`;

  const type = getTransactionType(isCheckoutRamp, tx.Direction);

  const rampFee = getRampFeeFiatAmount({
    feeMinimumFiatAmount: config.cko.feeMinimumFiatAmount,
    feePercentage: config.cko.feePercentage,
    fiatAmount: fiatAmountObj?.Amount,
    fiatCurrencyCode: fiatCurrency?.code,
    isManualRamp,
    manualRampConfig: config.manualRamp,
    sourceRecords: tx.SourceRecords,
  });
  const feeAmount = isRamp ? rampFee : getSourceRecordFee(tx.SourceRecords);
  return {
    channelFiatAmount: tx.FiatPayment?.Amount,
    created: created.toLocaleString(),
    cryptoAmount: positiveOrNegative(tx.Amount, tx.Direction),
    cryptoCurrency,
    cryptoNetwork: cryptoNetworkFromId(tx.Network),
    dataQa: {
      noahId: tx.NoahID,
      orderId: tx.WithdrawOrderID,
      publicId: tx.PublicID,
    },
    description: tx.Description,
    direction: tx.Direction,
    feeAmount,
    fiatAmount,
    fiatAmountWithFee,
    fiatCurrency,
    fiatPaymentMethod: getFiatPaymentMethodUi(
      tx.FiatPaymentMethod?.DisplayDetails as TransactionFiatPaymentMethodDetails,
    ),
    hasExpired,
    hasFee: isRamp || !isUndefinedOrNull(feeAmount),
    id: tx.NoahID || tx.WithdrawOrderID,
    isCustomer: !!tx.CustomerID,
    isRamp,
    mainPrimaryContent: getMainPrimaryContent({ cryptoCurrency, isInternal, isRamp, tx }),
    mainSecondaryContent: getMainSecondaryContent(tx, hasExpired),
    marketPrice: tx.MarketAmount?.Price,
    noahID: tx.NoahID,
    publicID: tx.PublicID,
    status,
    statusText: getStatusText(tx, hasExpired),
    type,
    withdrawOrderID: tx.WithdrawOrderID,
  };
}
