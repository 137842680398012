export const userRoutes = {
  acceptUpdates: {
    path: '/user/accept-updates',
  },
  accounts: {
    path: '/user/accounts',
    title: 'Linked accounts',
  },
  base: {
    path: '/user',
  },
  consent: {
    path: '/user/consent',
    title: 'We value your privacy',
  },
  profile: {
    path: '/user/profile',
    title: 'Personal Details',
  },
  settings: {
    accountLimits: {
      path: '/user/limits',
      title: 'Account Limits',
    },
    base: {
      path: '/user/settings',
      title: 'Settings',
    },
    bitcoinUnit: {
      path: '/user/settings/bitcoin-unit',
      title: 'Bitcoin unit',
    },
    consent: {
      path: '/user/settings/consent',
      title: 'We value your privacy',
    },
    displayCurrency: {
      path: '/user/settings/display-currency',
      title: 'Display currency',
    },
    editPaymentMethod: {
      path: '/user/settings/payment-methods/:id',
      title: 'Edit Payment Method',
    },
    newPaymentMethod: {
      path: '/user/settings/new-payment-method',
      title: 'New Payment Method',
    },
    paymentCurrency: {
      path: '/user/settings/payment-currency',
      title: 'Payment currency',
    },
    paymentMethods: {
      path: '/user/settings/payment-methods',
      title: 'Saved Payment Methods',
    },
    pin: {
      create: {
        pin: { path: '/user/settings/pin/create/pin', title: 'Create PIN' },
        secretPhrase: {
          path: '/user/settings/pin/create/secret-phrase',
          title: 'PIN Recovery',
        },
        success: {
          path: '/user/settings/pin/create/success',
          title: 'PIN Created',
        },
      },
      reset: {
        pin: {
          path: '/user/settings/pin/reset/pin',
          title: 'Recover PIN',
        },
        secretPhrase: {
          path: '/user/settings/pin/reset/secret-phrase',
          title: 'Forgot PIN',
        },
        success: {
          path: '/user/settings/pin/reset/success',
          title: 'PIN Updated',
        },
      },
      revocation: {
        revoke: {
          path: '/user/settings/pin/revoke',
          title: 'PIN Revocation Started',
        },
        unrevoke: {
          path: '/user/settings/pin/unrevoke',
          title: 'PIN Unrevoked',
        },
      },
      security: {
        path: '/user/settings/pin/security',
        title: 'Security PIN',
      },
      threshold: {
        path: '/user/settings/pin/threshold',
        title: 'Transaction PIN Threshold',
      },
      update: {
        currentPin: {
          path: '/user/settings/pin/update/current-pin',
          title: 'Update PIN',
        },
        pin: {
          path: '/user/settings/pin/update/pin',
          title: 'Update PIN',
        },
        success: {
          path: '/user/settings/pin/update/success',
          title: 'PIN Updated',
        },
      },
    },
    primaryCurrency: {
      path: '/user/settings/primary-currency',
      title: 'Primary currency type',
    },
  },
  username: {
    path: '/user/username',
    title: 'Choose a username',
  },
};

/**
 * Export the paths that need to route to this router
 */
export const postSignupRoutes = [
  userRoutes.username.path,
  userRoutes.consent.path,
  userRoutes.acceptUpdates.path,
];
