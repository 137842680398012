import { useEffect } from 'react';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { matchPath, useDialogs } from '@noah-labs/fe-shared-ui-components';
import { useRouter, useUserLocalStorage } from '@noah-labs/fe-shared-ui-shared';
import { CreatePinReminderDialog } from '@noah-labs/fe-shared-ui-signing';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import dayjs from 'dayjs';
import { useSigning } from './useSigning';

const REMINDER_DAYS = 1;
const DIALOG_KEY = 'pin-create-reminder';

type PpUseCreatePinReminder = {
  routeMatch: string;
};

export function useCreatePinReminder({ routeMatch }: PpUseCreatePinReminder): void {
  const { data: userData } = useUserInitUi();
  const {
    isLoaded: isStorageLoaded,
    pinReminderDismissedTimestamp,
    setPinReminderDismissedTimestamp,
  } = useUserLocalStorage(userData?.userProfile.UserID);
  const { closeDialog, pushDialog } = useDialogs();
  const { sdStatus } = useSigning();
  const { pathname } = useRouter();
  const isRouteMatch = matchPath(pathname, routeMatch);
  const shouldCheckLastSeen =
    isRouteMatch?.isExact && sdStatus?.pinSetupRequired && isStorageLoaded;

  useEffect(() => {
    if (!shouldCheckLastSeen) {
      return;
    }

    const lpr = parseInt(pinReminderDismissedTimestamp, 10);
    if (!Number.isNaN(lpr) && dayjs().diff(dayjs.unix(lpr), 'day') < REMINDER_DAYS) {
      return;
    }

    pushDialog({
      Content: (
        <CreatePinReminderDialog
          setupTo={userRoutes.settings.pin.create.pin.path}
          onClose={(): void => {
            setPinReminderDismissedTimestamp(dayjs().unix().toString());
            closeDialog(DIALOG_KEY);
          }}
        />
      ),
      key: DIALOG_KEY,
      low: true,
      onDismiss: () => {
        setPinReminderDismissedTimestamp(dayjs().unix().toString());
      },
    });
  }, [
    closeDialog,
    pinReminderDismissedTimestamp,
    pushDialog,
    setPinReminderDismissedTimestamp,
    shouldCheckLastSeen,
  ]);
}
