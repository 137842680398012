import type {
  AccountType,
  CurrencyCode,
  FiatCurrencyCode,
  Network,
} from '@noah-labs/shared-schema-gql';
import { capitalizeFirstLetter } from '@noah-labs/shared-util-vanilla';
import { cryptoCurrencyFromCode } from '../cryptoCurrencies';
import { cryptoNetworkFromId } from '../cryptoNetworks';
import { fiatCurrencyFromCode } from '../fiatCurrencies';
import { useRouter } from '../routing/useRouter';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUI, TpFiatCurrencyUI } from '../types';

type TpWalletRouterLowerParams = {
  accountType: Lowercase<AccountType> | '';
  currencyCode: Lowercase<CurrencyCode> | '';
  fiatCurrencyCode?: Lowercase<FiatCurrencyCode> | '';
  network: Lowercase<Network> | '';
};

export type TpNormalisedParams = {
  AccountType: AccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoNetwork: TpCryptoNetworkUI | undefined;
  fiatCurrency: TpFiatCurrencyUI | undefined;
  params: TpWalletRouterLowerParams;
};

export type PpUseWallet = {
  accountType: AccountType | '';
  currencyCode: CurrencyCode | '';
  fiatCurrencyCode?: FiatCurrencyCode | '';
  network?: Network | '';
};

const emptyWalletParams: PpUseWallet = {
  accountType: '',
  currencyCode: '',
  fiatCurrencyCode: '',
  network: '',
};

export function useWalletParams(fallback: PpUseWallet = emptyWalletParams): TpNormalisedParams {
  const { useParams } = useRouter();
  const params = {
    ...fallback,
    ...useParams(),
  };

  const at = params.accountType.toLowerCase();
  const cc = params.currencyCode.toLowerCase();
  const nt = params.network?.toLowerCase();
  const fc = params.fiatCurrencyCode?.toLocaleLowerCase();

  const AT = capitalizeFirstLetter(at);
  const CC = params.currencyCode.toUpperCase();
  const FC = params.fiatCurrencyCode?.toUpperCase();

  return {
    AccountType: AT as AccountType,
    cryptoCurrency: cryptoCurrencyFromCode(CC as CurrencyCode),
    cryptoNetwork: nt ? cryptoNetworkFromId(nt as Lowercase<Network>) : undefined,
    fiatCurrency: FC ? fiatCurrencyFromCode(FC as FiatCurrencyCode) : undefined,
    params: {
      accountType: at as Lowercase<AccountType>,
      currencyCode: cc as Lowercase<CurrencyCode>,
      fiatCurrencyCode: fc as Lowercase<FiatCurrencyCode>,
      network: nt as Lowercase<Network>,
    },
  };
}
