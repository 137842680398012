import { z } from 'zod';
import { billingAddressSchema } from './billingAddress';

const baseBankSchema = z.object({
  accountHolderName: z.string().min(1, 'Account holder name is required'),
  billingAddress: billingAddressSchema,
  saveAccount: z.boolean().optional(),
});

const baseBankSchemaWithCurrency = baseBankSchema.extend({
  accountCurrency: z.string().min(1, 'Account currency is required'),
  accountCurrencyName: z.string().min(1, 'Account currency is required'),
});

const swiftRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

const defaultBankAccountSchema = z.object({
  accountNumber: z.string().min(1, 'IBAN is required'),
  bankCode: z.string().regex(swiftRegex, 'Invalid SWIFT/BIC'),
});

export const defaultBankSchema = defaultBankAccountSchema.merge(baseBankSchema);
export const defaultBankSchemaWithCurrency = defaultBankAccountSchema.merge(
  baseBankSchemaWithCurrency,
);

export const usBankSchema = z
  .object({
    accountNumber: z.string().min(1, 'Account number is required'),
    bankCode: z.string().min(1, 'Wire routing number is required'),
  })
  .merge(baseBankSchemaWithCurrency);

export const sortCodeSchema = z
  .object({
    accountNumber: z.string().regex(/^\d{8}$/, 'Invalid account number'),
    bankCode: z.string().regex(/^\d{6}$/, 'Invalid sort code'),
  })
  .merge(baseBankSchemaWithCurrency);
