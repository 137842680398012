import React from 'react';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import {
  AppMain,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';

type PpSecurityPinScene = {
  pinSetupRequired: boolean;
};

export function SecurityPinScene({ pinSetupRequired }: PpSecurityPinScene): React.ReactElement {
  return (
    <AppMain>
      <SceneMain dense>
        <List disablePadding spacing={1}>
          {pinSetupRequired && (
            <ListItem>
              <ListItemButton href={userRoutes.settings.pin.create.pin.path}>
                <ListItemCard withHoverEffect>
                  <ListItemContent>
                    <ListItemStartContent>
                      <ListItemPrimaryText>Create PIN</ListItemPrimaryText>
                      <ListItemSecondaryText>Create a new security PIN</ListItemSecondaryText>
                    </ListItemStartContent>
                    <ListItemEndContent>
                      <ChevronRightIcon fontSize="small" />
                    </ListItemEndContent>
                  </ListItemContent>
                </ListItemCard>
              </ListItemButton>
            </ListItem>
          )}

          {!pinSetupRequired && (
            <React.Fragment>
              <ListItem>
                <ListItemButton href={userRoutes.settings.pin.update.currentPin.path}>
                  <ListItemCard withHoverEffect>
                    <ListItemContent>
                      <ListItemStartContent>
                        <ListItemPrimaryText>Update PIN</ListItemPrimaryText>
                        <ListItemSecondaryText>
                          You will be asked for your current PIN
                        </ListItemSecondaryText>
                      </ListItemStartContent>
                      <ListItemEndContent>
                        <ChevronRightIcon fontSize="small" />
                      </ListItemEndContent>
                    </ListItemContent>
                  </ListItemCard>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href={userRoutes.settings.pin.reset.secretPhrase.path}>
                  <ListItemCard withHoverEffect>
                    <ListItemContent>
                      <ListItemStartContent>
                        <ListItemPrimaryText>Forgot PIN</ListItemPrimaryText>
                        <ListItemSecondaryText>
                          Use your recovery phrase to reset your PIN
                        </ListItemSecondaryText>
                      </ListItemStartContent>
                      <ListItemEndContent>
                        <ChevronRightIcon fontSize="small" />
                      </ListItemEndContent>
                    </ListItemContent>
                  </ListItemCard>
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          )}
        </List>
      </SceneMain>
    </AppMain>
  );
}
