import { useState } from 'react';
import { CreatePinRecover, EnterCurrentPin } from '@noah-labs/fe-shared-feature-signing';
import { AppContainer, AppHeaderTitle, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { PinSuccessScene } from '@noah-labs/fe-shared-ui-signing/src/scenes/PinSuccess';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { SafeWrapper } from '@noah-labs/shared-cryptography';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../components/layout/AppHeaderData';
import { AccessControlData } from '../auth/AccessControlData';

/**
 * Users attempt to update their security PIN using its current PIN.
 */
export function UpdatePinRouter(): React.ReactElement {
  const [mnemonic, setMnemonic] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route path={userRoutes.settings.pin.update.currentPin.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.settings.pin.update.currentPin.title}>
            <AppHeaderData helpButton backTo={userRoutes.settings.pin.security.path}>
              <AppHeaderTitle>{userRoutes.settings.pin.update.currentPin.title}</AppHeaderTitle>
            </AppHeaderData>
            <EnterCurrentPin
              setMnemonic={setMnemonic}
              successTo={userRoutes.settings.pin.update.pin.path}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route path={userRoutes.settings.pin.update.pin.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.settings.pin.update.pin.title}>
            <AppHeaderData helpButton backTo={userRoutes.settings.pin.update.currentPin.path}>
              <AppHeaderTitle>{userRoutes.settings.pin.update.pin.title}</AppHeaderTitle>
            </AppHeaderData>
            <CreatePinRecover
              exitTo={userRoutes.settings.pin.security.path}
              phrase={mnemonic}
              sceneConfirmTitle="Confirm your new security PIN"
              sceneTitle="Create a new security PIN"
              successTo={userRoutes.settings.pin.update.success.path}
            />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route path={userRoutes.settings.pin.update.success.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.settings.pin.update.success.title}>
            <AppHeaderData helpButton />
            <PinSuccessScene
              sceneTitle="PIN updated sucessfully"
              successTo={walletRoutes().base.path}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
