import type { TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';
import bitcoin from '@noah-labs/fe-shared-ui-assets/svg/icons/bitcoin.svg';
import ethereum from '@noah-labs/fe-shared-ui-assets/svg/icons/ethereum.svg';
import noahcircle from '@noah-labs/fe-shared-ui-assets/svg/icons/noah-circle.svg';
import polygon from '@noah-labs/fe-shared-ui-assets/svg/icons/polygon.svg';
import tron from '@noah-labs/fe-shared-ui-assets/svg/icons/tron.svg';
import usdcoin from '@noah-labs/fe-shared-ui-assets/svg/icons/usdcoin.svg';
import usdt from '@noah-labs/fe-shared-ui-assets/svg/icons/usdt.svg';
import type { TpCryptoCurrency } from '@noah-labs/shared-currencies';
import { cryptoCurrencies, getEnvCurrencyCode } from '@noah-labs/shared-currencies';
import type { CurrencyCode, Network } from '@noah-labs/shared-schema-gql';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { cryptoNetworkFromId } from './cryptoNetworks';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUI, TpExtraCryptoCurrencyUI } from './types';

const extraCryptoCurrencyUi: Record<CurrencyCode, TpExtraCryptoCurrencyUI> = {
  BTC: {
    styles: {
      avatar: { backgroundColor: '#fff3ea' },
    },
    svg: bitcoin,
  },
  BTC_TEST: {
    styles: {
      avatar: { backgroundColor: '#fff3ea' },
    },
    svg: bitcoin,
  },
  ETH: {
    // TODO (cs): check with design on these backgroubd colors
    styles: {
      avatar: { backgroundColor: '#f5f5f5' },
    },
    svg: ethereum,
  },
  ETH_TEST_SEPOLIA: {
    styles: {
      avatar: { backgroundColor: '#f5f5f5' },
    },
    svg: ethereum,
  },
  MATIC: {
    styles: {
      avatar: { backgroundColor: '#f5f5f5' },
    },
    svg: polygon,
  },
  MATIC_TEST: {
    styles: {
      avatar: { backgroundColor: '#f5f5f5' },
    },
    svg: polygon,
  },
  NOAH: {
    styles: {
      avatar: { backgroundColor: '#f5f5f5' },
    },
    svg: noahcircle,
  },
  TRX: {
    styles: {
      avatar: { backgroundColor: '#f5f5f5' },
    },
    svg: tron,
  },
  TRX_TEST: {
    styles: {
      avatar: { backgroundColor: '#f5f5f5' },
    },
    svg: tron,
  },
  USDC: {
    styles: {
      avatar: { backgroundColor: '#eef4fc' },
    },
    svg: usdcoin,
  },
  USDC_TEST: {
    styles: {
      avatar: { backgroundColor: '#eef4fc' },
    },
    svg: usdcoin,
  },
  USDT: {
    styles: {
      avatar: { backgroundColor: '#dcefea' },
    },
    svg: usdt,
  },
  USDT_TEST: {
    styles: {
      avatar: { backgroundColor: '#dcefea' },
    },
    svg: usdt,
  },
};

// This function takes networks and returns a function that can be used to filter networks
function createAvailableNetworks(networks: Network[]) {
  return (feature: TpFeFeatureFlag): TpCryptoNetworkUI[] =>
    networks.filter((network) => feature.Networks?.includes(network)).map(cryptoNetworkFromId);
}

function createCurrency({
  code,
  supportedNetworks,
  ...rest
}: TpCryptoCurrency): TpCryptoCurrencyUI {
  return {
    ...rest,
    ...extraCryptoCurrencyUi[code],
    availableNetworks: createAvailableNetworks(supportedNetworks),
    code,
    supportedNetworks,
  };
}

export const cryptoCurrenciesUi = Object.entries(cryptoCurrencies).reduce(
  (acc, [key, value]) => {
    acc[key as CurrencyCode] = createCurrency(value);
    return acc;
  },
  {} as Record<CurrencyCode, TpCryptoCurrencyUI>,
);

export function cryptoCurrencyFromString(code: string | undefined): TpCryptoCurrencyUI | undefined {
  if (typeof code !== 'string') {
    return undefined;
  }
  const codeUpper = code.toUpperCase();
  // Happy to use 'as' here because it will return undefined if it doesn't exist
  return cryptoCurrenciesUi[codeUpper as CurrencyCode];
}

export function cryptoCurrencyFromCode(code: CurrencyCode): TpCryptoCurrencyUI {
  return cryptoCurrenciesUi[code];
}

export function getEnvCryptoCurrencyFromCode(
  code: CurrencyCode,
  isProd: boolean,
): TpCryptoCurrencyUI {
  const cc = getEnvCurrencyCode(code, isProd);
  return cryptoCurrenciesUi[cc];
}

export function getAvailableCryptoNetworks(
  CC: CurrencyCode,
  ff: TpFeFeatureFlag | undefined,
  filter?: Network[],
): Array<TpCryptoNetworkUI> {
  if (!ff) {
    return [];
  }
  const cryptoCurrency = cryptoCurrencyFromCode(CC);
  const availableNetworks = cryptoCurrency.availableNetworks(ff);
  if (filter) {
    return availableNetworks.filter((n) => filter.includes(n.id));
  }
  return availableNetworks;
}

export const unitCurrencyItems = [
  {
    label: 'Bitcoin',
    svg: bitcoin,
    value: CurrencyUnit.Default,
  },
  {
    label: 'Satoshis',
    svg: bitcoin,
    value: CurrencyUnit.SATS,
  },
];
