import React, { useCallback, useEffect, useMemo } from 'react';
import {
  useCountriesForFeature,
  useCountryFromCode,
} from '@noah-labs/fe-shared-data-access-countries';
import { useFiatPaymentMethodsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { mapPaymentMethods } from '@noah-labs/fe-shared-feature-wallet';
import { generatePath, LoadingPage } from '@noah-labs/fe-shared-ui-components';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpPaymentBank, TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import {
  fiatCurrencyFromCode,
  TpPaymentMethod,
  useRouter,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { useLocation } from 'react-router-dom';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { SelectPayoutMethodScene } from '../../scenes/SelectPayoutMethod';
import type { StSellRouter } from './SellRouter';

export function SelectPayoutMethod({
  state,
  updateState,
}: TpStateMachine<StSellRouter>): React.ReactElement {
  const { state: locationState } = useLocation<{ successTo: string } | undefined>();
  const { push } = useRouter();
  const { params } = useWalletParams();
  const { data: userData } = useUserInitUi();

  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.LatestCountry,
    [Feature.Sell],
    webConfigBrowser.regions.ProhibitedCountries,
    webConfigBrowser.regions.RestrictedRegions,
  );
  const country = useCountryFromCode(countriesData?.defaultCountry);

  const { data: savedMethods } = useFiatPaymentMethodsQuery(undefined, {
    select: mapPaymentMethods,
  });

  const defaultCountryValues = useMemo(() => {
    if (!country || !countriesData?.defaultCountry) {
      return undefined;
    }

    return {
      Country: countriesData.defaultCountry,
      CountryCurrency: country.currencies[0],
      CountryName: country.name,
    };
  }, [country, countriesData?.defaultCountry]);

  const onNewCardClick = useCallback(() => {
    // Forward traffic to successTo if it exists, otherwise to enter amount
    push({
      pathname: generatePath(walletRoutes().sell.enterPaymentInfo.path, params),
      state: {
        successTo:
          locationState?.successTo ?? generatePath(walletRoutes().sell.enterAmount.path, params),
      },
    });
  }, [push, locationState?.successTo, params]);

  const onNewBankAccountClick = useCallback(
    (selectedCountry: CountryCode) => {
      updateState({
        selectedCountry,
      });

      // Forward traffic to successTo if it exists, otherwise to enter amount
      push({
        pathname: generatePath(walletRoutes().sell.enterAccountDetails.path, params),
        state: {
          successTo:
            locationState?.successTo ?? generatePath(walletRoutes().sell.enterAmount.path, params),
        },
      });
    },
    [push, locationState?.successTo, updateState, params],
  );

  const updateSelectedPayoutMethod = useCallback(
    (method: TpPaymentCard | TpPaymentBank) => {
      switch (method.payoutMethod) {
        case TpPaymentMethod.Card: {
          const cardPaymentMethod = method as TpPaymentCard;

          updateState({
            fiatCurrency: userData?.userProfile.fiatPaymentCurrency,
            payoutMethod: cardPaymentMethod.payoutMethod,
            selectedPayoutBank: undefined,
            selectedPayoutCard: cardPaymentMethod,
          });
          break;
        }

        case TpPaymentMethod.BankTransfer: {
          const bankPaymentMethod = method as TpPaymentBank;

          updateState({
            fiatCurrency: fiatCurrencyFromCode(bankPaymentMethod.accountCurrency),
            payoutMethod: bankPaymentMethod.payoutMethod,
            selectedPayoutBank: bankPaymentMethod,
            selectedPayoutCard: undefined,
          });
          break;
        }

        default:
          break;
      }
    },
    [updateState, userData?.userProfile.fiatPaymentCurrency],
  );

  const onPayoutMethodClick = useCallback(
    (method: TpPaymentCard | TpPaymentBank) => {
      updateSelectedPayoutMethod(method);

      if (!locationState?.successTo) {
        push(generatePath(walletRoutes().sell.enterAmount.path, params));
        return;
      }
      push(generatePath(locationState.successTo));
    },
    [push, locationState?.successTo, params, updateSelectedPayoutMethod],
  );

  /**
   * If a payout method exists and no payout method is selected, preselect it and redirect to enter amount screen
   */
  useEffect(() => {
    if (
      isFalseyOrEmptyArray(savedMethods) ||
      state.selectedPayoutCard ||
      state.selectedPayoutBank
    ) {
      return;
    }

    const savedMethod = savedMethods.find((method) => method.payoutSupported);
    if (!savedMethod) {
      return;
    }

    updateSelectedPayoutMethod(savedMethod);
    push(generatePath(walletRoutes().sell.enterAmount.path, params));
  }, [
    state.selectedPayoutCard,
    state.selectedPayoutBank,
    push,
    savedMethods,
    params,
    updateSelectedPayoutMethod,
  ]);

  if (!savedMethods) {
    return <LoadingPage />;
  }

  return (
    <SelectPayoutMethodScene
      countries={countriesData?.countries}
      defaultCountryValues={defaultCountryValues}
      pageTitle="Sell"
      savedPayoutMethods={savedMethods}
      onNewBankAccountClick={onNewBankAccountClick}
      onNewCardClick={onNewCardClick}
      onPayoutMethodClick={onPayoutMethodClick}
    />
  );
}
