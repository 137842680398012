import { useRef } from 'react';
import { IconButton, ListItemIcon, Stack, Typography } from '@mui/material';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import { RestrictedFeature } from '@noah-labs/fe-shared-feature-user';
import { AccountLimitsIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/AccountLimitsIcon';
import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import { CreditCardIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CreditCardIcon';
import { DiscordIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DiscordIcon';
import { DocumentIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DocumentIcon';
import { EyeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/EyeIcon';
import { FaqIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/FaqIcon';
import { InfoIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/InfoIcon';
import { KeyholeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/KeyholeIcon';
import { LinkIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LinkIcon';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import { LogoutIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LogoutIcon';
import { ProfileIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ProfileIcon';
import { TwitterIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/TwitterIcon';
import {
  AppContainer,
  AppHeader,
  AppMain,
  DefaultAvatar,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  ListSection,
  MuiSvgIcon,
  SceneMain,
  SceneParagraph,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import type {
  TpCryptoCurrencyUI,
  TpDialogToggle,
  TpFiatCurrencyUI,
} from '@noah-labs/fe-shared-ui-shared';
import { authRoutes, kycRoutes, userRoutes } from '@noah-labs/fe-shared-util-routes';
import { webLinks } from '@noah-labs/shared-constants';
import type { Maybe } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { ReferFriendsSocialShareCard } from '../../rewards/components/cards/ReferFriendsSocialShareCard';
import { shareButtons } from '../../rewards/data/social';
import { LightningAddressDialog } from '../components/dialogs/LightningAddressDialog';
import { LnUrlChip } from '../components/LnUrlChip/LnUrlChip';
import { SignInWithBiometricToggle } from '../components/SignInWithBiometricToggle';
import type { TpKycApprovalStatusData } from '../data/useKycApprovalStatusData';

export type PpSettingsScene = {
  avatarAlt?: string;
  avatarContent: string | undefined;
  backTo: string;
  btcUnit?: string;
  displayCurrency?: TpFiatCurrencyUI;
  email?: string;
  kycApprovalStatusData?: TpKycApprovalStatusData;
  lnAddress?: string;
  pageTitle: string;
  paymentCurrency?: TpFiatCurrencyUI;
  primaryCurrency?: TpCryptoCurrencyUI | TpFiatCurrencyUI;
  referralUrl: string | undefined;
  usernameDisplay?: Maybe<string>;
};

export function SettingsScene({
  avatarAlt,
  avatarContent,
  backTo,
  btcUnit,
  displayCurrency,
  email,
  kycApprovalStatusData,
  lnAddress,
  pageTitle,
  paymentCurrency,
  primaryCurrency,
  referralUrl,
  usernameDisplay,
}: PpSettingsScene): React.ReactElement {
  const showAddressDialog = useRef<TpDialogToggle>(null);
  const { track } = useAnalytics();

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeader backTo={backTo} />
        <SceneMain dense dataQa="settings">
          <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
            <LightningAddressDialog ref={showAddressDialog} />
          </RestrictedFeature>

          <Stack spacing={3}>
            <Stack spacing={1}>
              <Stack alignItems="flex-start" direction="row" spacing={2}>
                <Stack
                  direction="column"
                  flexGrow={1}
                  // minWidth: 0 is needed to prevent the text from overflowing, see: https://css-tricks.com/flexbox-truncated-text/
                  spacing={1}
                  sx={{ minWidth: 0 }}
                >
                  <Typography noWrap variant="subHeadingM">
                    <TextOrSkeleton>{usernameDisplay}</TextOrSkeleton>
                  </Typography>
                  <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
                    <Stack alignItems="center" direction="row">
                      <SceneParagraph>Your NOAH Lightning Address</SceneParagraph>
                      <IconButton onClick={(): void => showAddressDialog.current?.toggle()}>
                        <InfoIcon />
                      </IconButton>
                    </Stack>
                  </RestrictedFeature>
                </Stack>
                <DefaultAvatar alt={avatarAlt} size={5.25} userInitials={avatarContent} />
              </Stack>

              <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
                <LnUrlChip lnAddress={lnAddress} />
              </RestrictedFeature>
            </Stack>

            <ListSection>
              <List disablePadding spacing={1}>
                <RestrictedFeature needs={[Feature.UIRewards]}>
                  <Stack marginBottom={1}>
                    <ReferFriendsSocialShareCard
                      referralUrl={referralUrl}
                      shareButtons={shareButtons}
                    />
                  </Stack>
                </RestrictedFeature>

                <ListItem>
                  <ListItemButton
                    className={webConfigBrowser.intercom.customLauncherSelector}
                    data-qa={webConfigBrowser.intercom.customLauncherSelector}
                    // use an empty onclick to force use of a button
                    onClick={(): void => {}}
                  >
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <FaqIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Help</ListItemPrimaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>

                <RestrictedFeature needs={[Feature.UIPromotions]}>
                  <ListItem>
                    <ListItemButton
                      href={webLinks.social.twitter}
                      onClick={(): void =>
                        track(TpAnalyticsEvent.SocialCardClicked, {
                          socialMedia: 'Twitter',
                        })
                      }
                    >
                      <ListItemCard withHoverEffect>
                        <ListItemContent>
                          <ListItemIcon>
                            <TwitterIcon />
                          </ListItemIcon>
                          <ListItemStartContent>
                            <ListItemPrimaryText>Follow us on X</ListItemPrimaryText>
                            <ListItemSecondaryText>
                              Join the community and receive a share of the millions in sats given
                              away on X (formerly known as Twitter).
                            </ListItemSecondaryText>
                          </ListItemStartContent>
                        </ListItemContent>
                      </ListItemCard>
                    </ListItemButton>
                  </ListItem>

                  <ListItem>
                    <ListItemButton
                      href={webLinks.social.discord}
                      onClick={(): void =>
                        track(TpAnalyticsEvent.SocialCardClicked, {
                          socialMedia: 'Discord',
                        })
                      }
                    >
                      <ListItemCard withHoverEffect>
                        <ListItemContent>
                          <ListItemIcon>
                            <DiscordIcon />
                          </ListItemIcon>
                          <ListItemStartContent>
                            <ListItemPrimaryText>Join the Discord community</ListItemPrimaryText>
                            <ListItemSecondaryText>
                              Stay in the loop with the latest news on NOAH
                            </ListItemSecondaryText>
                          </ListItemStartContent>
                        </ListItemContent>
                      </ListItemCard>
                    </ListItemButton>
                  </ListItem>
                </RestrictedFeature>
              </List>
            </ListSection>

            {/* General */}
            <ListSection title="General" titleColor="text.light">
              <List disablePadding spacing={1}>
                <ListItem>
                  <ListItemButton
                    data-qa="display-currency-button"
                    href={userRoutes.settings.displayCurrency.path}
                  >
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        {displayCurrency?.svg && (
                          <ListItemIcon>
                            <MuiSvgIcon svg={displayCurrency.svg} />
                          </ListItemIcon>
                        )}
                        <ListItemStartContent>
                          <ListItemPrimaryText>Display currency</ListItemPrimaryText>
                          <ListItemSecondaryText>
                            <TextOrSkeleton>{displayCurrency?.code}</TextOrSkeleton>
                          </ListItemSecondaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>

                <RestrictedFeature needs={[[Feature.Buy, Feature.Sell]]}>
                  <ListItem>
                    <ListItemButton
                      data-qa="payment-currency-button"
                      href={userRoutes.settings.paymentCurrency.path}
                    >
                      <ListItemCard withHoverEffect>
                        <ListItemContent>
                          {paymentCurrency?.svg && (
                            <ListItemIcon>
                              <MuiSvgIcon svg={paymentCurrency.svg} />
                            </ListItemIcon>
                          )}
                          <ListItemStartContent>
                            <ListItemPrimaryText>Payment currency</ListItemPrimaryText>
                            <ListItemSecondaryText>
                              <TextOrSkeleton>{paymentCurrency?.code}</TextOrSkeleton>
                            </ListItemSecondaryText>
                          </ListItemStartContent>
                          <ListItemEndContent>
                            <ChevronRightIcon fontSize="small" />
                          </ListItemEndContent>
                        </ListItemContent>
                      </ListItemCard>
                    </ListItemButton>
                  </ListItem>
                </RestrictedFeature>

                <ListItem>
                  <ListItemButton
                    data-qa="bitcoin-unit-button"
                    href={userRoutes.settings.bitcoinUnit.path}
                  >
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <BitcoinIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Bitcoin unit</ListItemPrimaryText>
                          <ListItemSecondaryText>
                            <TextOrSkeleton>{btcUnit}</TextOrSkeleton>
                          </ListItemSecondaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton
                    data-qa="primary-currency-button"
                    href={userRoutes.settings.primaryCurrency.path}
                  >
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        {primaryCurrency?.svg && (
                          <ListItemIcon>
                            <MuiSvgIcon svg={primaryCurrency.svg} />
                          </ListItemIcon>
                        )}
                        <ListItemStartContent>
                          <ListItemPrimaryText>Primary currency</ListItemPrimaryText>
                          <ListItemSecondaryText>
                            <TextOrSkeleton>{primaryCurrency?.code}</TextOrSkeleton>
                          </ListItemSecondaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton
                    data-qa="account-limits"
                    href={userRoutes.settings.accountLimits.path}
                  >
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <AccountLimitsIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Account limits</ListItemPrimaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton
                    data-qa="payment-methods"
                    href={userRoutes.settings.paymentMethods.path}
                  >
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <CreditCardIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Payment methods</ListItemPrimaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              </List>
            </ListSection>

            {/* Profile */}
            <ListSection title="Profile" titleColor="text.light">
              <List disablePadding spacing={1}>
                <ListItem>
                  <ListItemButton data-qa="personal-detail-button" href={userRoutes.profile.path}>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <ProfileIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Personal details</ListItemPrimaryText>
                        </ListItemStartContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton data-qa="linked-accounts-button" href={userRoutes.accounts.path}>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <LinkIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Linked accounts</ListItemPrimaryText>
                        </ListItemStartContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              </List>
            </ListSection>

            {/* Security */}
            <ListSection title="Security" titleColor="text.light">
              <List disablePadding spacing={1}>
                <ListItem>
                  <ListItemButton data-qa="privacy-consent" href={userRoutes.settings.consent.path}>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <EyeIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Privacy</ListItemPrimaryText>
                        </ListItemStartContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>

                <RestrictedFeature needs={[Feature.Pin]}>
                  <ListItem>
                    <ListItemButton
                      data-qa="security-pin"
                      href={userRoutes.settings.pin.security.path}
                    >
                      <ListItemCard withHoverEffect>
                        <ListItemContent>
                          <ListItemIcon>
                            <LockIcon />
                          </ListItemIcon>
                          <ListItemStartContent>
                            <ListItemPrimaryText>Security PIN</ListItemPrimaryText>
                          </ListItemStartContent>
                        </ListItemContent>
                      </ListItemCard>
                    </ListItemButton>
                  </ListItem>
                </RestrictedFeature>

                <RestrictedFeature needs={[Feature.Kyc]}>
                  <ListItem>
                    <ListItemButton data-qa="kyc" href={kycRoutes.base.path}>
                      <ListItemCard withHoverEffect>
                        <ListItemContent>
                          <ListItemIcon>
                            {kycApprovalStatusData?.Icon ?? <KeyholeIcon />}
                          </ListItemIcon>
                          <ListItemStartContent>
                            <ListItemPrimaryText>Identity verification</ListItemPrimaryText>
                            <ListItemSecondaryText
                              {...(kycApprovalStatusData?.color
                                ? {
                                    secondaryTypographyProps: {
                                      color: kycApprovalStatusData.color,
                                    },
                                  }
                                : {})}
                            >
                              {kycApprovalStatusData?.description}
                            </ListItemSecondaryText>
                          </ListItemStartContent>
                        </ListItemContent>
                      </ListItemCard>
                    </ListItemButton>
                  </ListItem>
                </RestrictedFeature>
                <SignInWithBiometricToggle />
              </List>
            </ListSection>

            {/* About Us */}
            <ListSection title="About Us" titleColor="text.light">
              <List disablePadding spacing={1}>
                <ListItem>
                  <ListItemButton data-qa="terms-button" href={webLinks.termsAndConditions.path}>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Terms and conditions</ListItemPrimaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton data-qa="privacy-button" href={webLinks.privacyPolicy.path}>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <DocumentIcon />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Privacy Policy</ListItemPrimaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              </List>
            </ListSection>

            {/* Logout */}
            <ListSection
              sx={{
                // needs extra specificity to override the stack margin
                '&&': {
                  mt: 7,
                },
              }}
            >
              <List disablePadding spacing={1}>
                <ListItem>
                  <ListItemButton data-qa="logout-button" href={authRoutes.signOut.path}>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemIcon>
                          <LogoutIcon color="error" />
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Log out</ListItemPrimaryText>
                          <ListItemSecondaryText>{email}</ListItemSecondaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              </List>
            </ListSection>
          </Stack>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
