import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

export type TpCurrencyForSelect = {
  label: string;
  symbol: string;
  value: string;
};
export type TpCurrenciesForSelect = Array<TpCurrencyForSelect> | undefined;

function compareCurrencyName(a: TpCurrencyForSelect, b: TpCurrencyForSelect): number {
  return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
}

export function isValidFiatCurrency(value: unknown): value is FiatCurrencyCode {
  return Object.values(FiatCurrencyCode).includes(value as FiatCurrencyCode);
}

async function currenciesForSelect(): Promise<TpCurrenciesForSelect> {
  const Currencies = await import('./currency-list');

  return Currencies.default.all
    .filter((currency) => currency.symbol)
    .map((currency) => ({
      label: currency.name,
      symbol: currency.symbol,
      value: currency.code,
    }))
    .sort(compareCurrencyName);
}

export function useCurrenciesForSelect(
  options?: UseQueryOptions<TpCurrenciesForSelect>,
): UseQueryResult<TpCurrenciesForSelect> {
  return useQuery({
    queryFn: currenciesForSelect,
    queryKey: ['CurrenciesForSelect'],
    ...options,
  });
}
